const boatsetterPaths = {
  aboutUs: '/about',
  account: '/account_settings',
  auth: {
    root: '/a/auth',
    verifyForgotPassword: '/a/auth/forgot-password/verify',
  },
  bachelorettePartyBoatRentals: '/bachelorette-party-boat-rentals',
  bachelorPartyBoatRentals: '/bachelor-party-boat-rentals',
  becomeACaptain: '/captains',
  blog: '/boating-resources',
  boatGuides: '/boat-rentals',
  boatRentals: '/boat-rentals',
  bookings: '/bookings',
  captainDashboard: '/captain',
  careers: '/jobs',
  catamaranRentals: '/catamaran-rentals',
  centerConsoleRentals: '/center-console-rentals',
  communityImpact: '/community-impact',
  editListing: '/a/boats',
  fishingBoatRentals: '/fishing-boat-rentals',
  fishingCharters: '/fishing-charters',
  insuranceAndSafety: '/legal/insurance-policy',
  jetSkis: '/jet-ski-rentals',
  listBoat: '/a/list-boat',
  logIn: '/a/login',
  logOut: '/a/logout',
  luxuryYachtCharters: '/luxury-yacht-charters',
  myBoats: '/manage_boats',
  newCollection: '/new',
  ownerDashboard: '/owner',
  partyBoatRentals: '/party-boat-rentals',
  pontoonBoatRentals: '/pontoon-boat-rentals',
  press: '/press',
  renterDashboard: '/account_settings',
  rulesAndRegulations: '/regulations',
  sailboatRentals: '/sailboat-rentals',
  search: '/a/boat-rentals/search',
  signUp: '/a/signup',
  summerCollection: '/summer_collection',
  whyList: '/why-list',
  yachtRentals: '/yacht-rentals',
  locations: {
    austin: '/boat-rentals/austin--tx--united-states',
    chicago: '/boat-rentals/chicago--il--united-states',
    destin: '/boat-rentals/destin--fl--united-states',
    fortLauderdale: '/boat-rentals/fort-lauderdale--fl--united-states',
    global: '/global',
    losAngeles: '/boat-rentals/los-angeles--ca--united-states',
    miami: '/boat-rentals/miami--fl--united-states',
    miamiBeach: '/boat-rentals/miami-beach--fl--united-states',
    newYorkCity: '/boat-rentals/new-york--ny--united-states',
    sanDiego: '/boat-rentals/san-diego--ca--united-states',
    sanFrancisco: '/boat-rentals/san-francisco--ca--united-states',
    sarasota: '/boat-rentals/sarasota--fl--united-states',
    seattle: '/boat-rentals/seattle--wa--united-states',
    tampaBay: '/boat-rentals/tampa--fl--united-states',
    washingtonDC: '/boat-rentals/washington--dc--united-states',

    lakeTravis: '/boat-rentals/lake-travis--tx--united-states',
    lakeTahoe: '/boat-rentals/lake-tahoe--united-states',
    lakeNorman: '/boat-rentals/lake-norman--nc--united-states',
    lakeLanier: '/boat-rentals/lake-lanier--ga--united-states',
    lakeGeorge: '/boat-rentals/lake-george--ny--united-states',
    lakeOfTheOzarks: '/boat-rentals/lake-of-the-ozarks--mo--united-states',
    lakeHavasu: '/boat-rentals/lake-havasu--united-states',
    lakeMead: '/boat-rentals/lake-mead--united-states',
    lakePowell: '/boat-rentals/lake-powell--united-states',
    lakeConroe: '/boat-rentals/lake-conroe--tx--united-states',
  },
  accountSettings: {
    password: {
      edit: '/account_settings/password/edit',
    },
  },
};

export const boatsetterSocials = {
  facebook: 'ttps://www.facebook.com/Boatsetter',
  instagram: 'https://www.instagram.com/Boatsetter/',
  pinterest: 'https://www.pinterest.com/Boatsetter1/_saved/',
  tiktok: 'https://www.tiktok.com/@boatsetter',
  twitter: 'https://twitter.com/Boatsetter',
  youtube: 'https://www.youtube.com/c/Boatsetter',
};

export const boatsetterFlags = {
  clerkSignup: 'clerk_signup',
};

export default boatsetterPaths;
